<template>
  <v-main>
    <v-container fluid>
      <v-alert
        type="info"
        border="left"
        class="my-4"
        colored-border
        elevation="2"
        >{{ $t('allergens.alert') }}</v-alert
      >

      <v-list two-line avatar elevation="2" class="mb-16">
        <v-subheader>{{ $t('allergens.title') }}</v-subheader>

        <v-list-item-group v-model="settings" multiple @change="select">
          <template v-for="allergen in allergens">
            <v-list-item :key="'allergen-' + allergen.id">
              <template v-slot:default="{ active }">
                <v-list-item-action class="mr-5">
                  <v-checkbox
                    color="primary"
                    :input-value="active"
                  ></v-checkbox>
                </v-list-item-action>

                <v-list-item-avatar class="mr-3 elevation-2" size="36">
                  <v-img :src="allergen.image_path"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    v-html="$options.filters.translate(allergen.name, $i18n)"
                  >
                  </v-list-item-title>
                  <v-list-item-subtitle
                    v-html="
                      $options.filters.translate(allergen.description, $i18n)
                    "
                  >
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn
                    icon
                    @click.stop.prevent="snackbar(allergen)"
                    @mousedown.stop.prevent
                    @touchstart.stop.prevent="snackbar(allergen)"
                  >
                    <v-icon color="primary lighten-1">mdi-information</v-icon>
                  </v-btn>
                </v-list-item-action>
              </template>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {},

  data: () => ({
    settings: [],
  }),

  computed: {
    ...mapGetters('allergens', {
      allergens: 'allergens',
      selected: 'selected',
    }),
  },

  created() {
    // FIXME: First check the user and the restaurant version

    this.allergens.forEach((allergen, index) => {
      if (this.selected.includes(allergen.id)) {
        this.settings.push(index);
      }
    });

    // this.$store
    //   .dispatch('allergens/load')
    //   .then(results => {
    //     results.data.allergens.forEach((allergen, index) => {
    //       if (this.selected.includes(allergen.id)) {
    //         this.settings.push(index);
    //       }
    //     });
    //   })
    //   .catch(error => {
    //     // We should show an error ...
    //     // FIXME: Show an error page
    //     this.error = error;
    //     console.log(error);
    //   });
  },

  methods: {
    select(settings) {
      let selected = this.allergens
        .filter((allergen, index) => settings.includes(index))
        .map(allergen => allergen.id);

      this.$store.dispatch('allergens/select', selected);
    },

    snackbar(allergen) {
      window.events.$emit('snackbar', allergen.description);
    },
  },
};
</script>
